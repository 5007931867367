<template>
  <div class="sshx">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>学生综合评价（成长画像）平台</h1>
          <p><a href="/">首页</a>
            <Icon class="arrow" type="ios-arrow-forward" />我们的优势
            <Icon class="arrow" type="ios-arrow-forward" />学生综合评价（成长画像）平台
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="12" :lg="12">
              <div class="inner-column" style="padding: 0">
                <div class="image">
                  <a href="#"><img src="../../assets/resource/sshx.png" alt="" /></a>
                </div>
              </div>
              </Col>

              <Col :xl="12" :lg="12">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title-icon">
                    <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
                  </div>
                  <h2>学生综合评价（成长画像）平台</h2>
                </div>
                <div class="text">
                  <p>
                    学生多维度评价与成长支持系统是利用大数据、人工智能等技术进行多源多维多模态的过程性数据采集，通过自动化评价、智能识别和感知系统、大数据分析等技术提供全方位、多层次的支持，从而构建基于学生成长过程的综合素质评价。通过采集学生成长数据，
                    对所采集的数据进行数据清洗、汇聚、建模分析，分析结果生成全方位的学生成长画像，学生成长画像在学生自身发展、教师教学、家校互动、区域教育治理等关键环节发挥重要的作用和意义。
                  </p>
                  <!-- <p>平台将一系列业务活动抽象为不同类型的“流程节点”，通过流程编辑器进行连接，可实现业务流程触发、流转、审批、消息推送等操作，
                    免去了代码编写工作，让使用者能够完全专注于校园业务场景，为学校提供智慧灵活的办公OA搭建工具。</p> -->
                </div>
              </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row style="padding-bottom:80px">
          <Col :xl="8" :lg="8" :md="12" :sm="24" v-for="(item, index) in feature" :key="index">
          <div class="feature-block-two">
            <div class="inner-box ">
              <div class="icon-box">
                <img :src="item.numberPic" alt="" />
              </div>
              <h3>{{ item.title }}</h3>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
          </Col>
        </Row>

        <div class="sec-title">
          <div class="title-icon">
            <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
          </div>
          <h2>图集</h2>
        </div>
        <Carousel v-model="value" loop autoplay dots="none" arrow="always" autoplay-speed="5000">
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/sshx1.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/sshx2.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/sshx3.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/sshx4.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/sshx5.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/sshx6.png" alt="" srcset="">
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import sshx from "./sshx.js";
export default sshx;
</script>

<style lang="less" scoped>
@import "./sshx.less";
</style>