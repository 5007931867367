export default {
    data() {
        return {
            feature: [{
                title: '多源、多维、多模态数据采集，让来源更丰富',
                numberPic: require("../../assets/icons/num1.png"),
                text: '通过移动端采集、数据对接采集、视觉识别采集、智能传感器采集、二维码采集、问卷测试采集、课程学习采集、答题采集等多种来源、多种维度，多种模态的方式对数据进行采集，让数据采集维度更多，评价数据更丰富。'
            }, {
                title: '高效的数据治理，让数据更精准',
                numberPic: require("../../assets/icons/num2.png"),
                text: '建立学校集中部署、高度统一的数据治理体系，开展对各类数据进行脱敏、整理、分类、标签、转换、整合等标准化治理，构建服务分析挖掘的融合数据仓库，通过大数据分析，实现数据的共享交换服务。'
            }, {
                title: '新颖的积分兑奖模式，让学生更踊跃',
                numberPic: require("../../assets/icons/num3.png"),
                text: '系统通过设置成长积分和可使用积分，来实现学生的荣誉评级和兑奖消费，学生可通过平台对虚拟货物的兑换，通过智能售货机、智能盲盒机实现成长积分的兑换，让学生参与评价的积极性更高。'
            }, {
                title: '数据汇聚融合，打造学生数字画像',
                numberPic: require("../../assets/icons/num4.png"),
                text: '通过对数据进行数据清洗、汇聚、建模分析，系统可以根据结果按照学期生成学生个人数字画像和班级群体数字画像以及学校群体数字画像，基于画像开展学生综合素质评价。'
            }, {
                title: '记录学生成长轨迹，汇聚成长点滴',
                numberPic: require("../../assets/icons/num5.png"),
                text: '系统通过各维度产生的学生个人数据，如学校统一记录的学科成绩、活动参与、比赛参与、课程参与等；班级教师记录的班级活动、精彩瞬间、班级荣誉、课堂表现；家长记录的日常生活、个人荣誉，系统会自动生成个人的成长轨迹。'
            }],
        }
    },
    created() {

    },
}